@import url(https://fonts.googleapis.com/css?family=BenchNine:300|Roboto|Open+Sans&display=swap);
@import url(https://fonts.googleapis.com/css?family=BenchNine:300|Roboto|Open+Sans&display=swap);
header,
section,
footer {
  min-width: 383px; }

.landing_LandingPage__3DhH4 {
  display: flex;
  flex-direction: column;
  max-width: 1500px;
  width: 100%;
  margin: 0 auto; }

.landing_mainText__14Q4V {
  font-family: "Roboto", sans-serif;
  font-size: 2rem;
  font-weight: 800;
  line-height: 60px;
  margin: 30px 0;
  min-width: 400px; }
  @media (max-width: 500px) {
    .landing_mainText__14Q4V {
      font-size: 1.5rem;
      margin: 15px 0; } }

.landing_logo__1BYzm {
  font-family: "BenchNine", sans-serif;
  font-size: 1.7rem;
  margin-bottom: 10px; }
  .landing_logo__1BYzm a {
    display: flex;
    color: white;
    text-decoration: none; }
    .landing_logo__1BYzm a p:first-child {
      font-weight: 800; }

header {
  background-image: url(/static/media/background.ec4d1589.png);
  background-repeat: no-repeat;
  background-size: 1500px 900px;
  min-height: 900px;
  position: relative;
  margin-bottom: 60px; }
  @media (max-width: 1075px) {
    header {
      min-height: 820px; } }
  header nav {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: 30px auto 0; }
    @media (max-width: 615px) {
      header nav {
        flex-direction: column;
        align-items: center; }
        header nav div {
          line-height: 60px; } }
    header nav div {
      font-family: "Roboto", sans-serif;
      font-size: 1rem;
      display: flex;
      justify-content: space-between; }
      header nav div.landing_account__1c7Ae {
        width: 20%; }
        @media (max-width: 1075px) {
          header nav div.landing_account__1c7Ae {
            width: 35%; } }
        @media (max-width: 615px) {
          header nav div.landing_account__1c7Ae {
            display: none; } }
      header nav div.landing_mobileMenu__uV8HV {
        display: none; }
        @media (max-width: 615px) {
          header nav div.landing_mobileMenu__uV8HV {
            display: inline;
            display: initial;
            position: absolute;
            right: 35px;
            top: 15px; } }
      header nav div svg {
        color: white; }
    header nav a {
      color: white;
      display: flex;
      letter-spacing: 1px;
      text-decoration: none; }
    header nav p:first-child {
      font-weight: 800; }
  header .landing_headerBackground__3f5F9 {
    width: 100%;
    height: auto; }
  header .landing_headerContent__29JpX {
    margin: 60px auto 0;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    width: 90%; }
    @media (max-width: 450px) {
      header .landing_headerContent__29JpX {
        margin: 0 auto; } }
  header img.landing_phone__1I20I {
    width: 375px; }
    @media (max-width: 1075px) {
      header img.landing_phone__1I20I {
        display: none; } }
  header .landing_headerTextContent__1xCxk {
    max-width: 555px; }
    @media (max-width: 1075px) {
      header .landing_headerTextContent__1xCxk {
        margin-top: 65px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center; } }
    header .landing_headerTextContent__1xCxk h2 {
      color: white;
      margin: 0;
      font-family: "Roboto", sans-serif;
      font-size: 5rem;
      font-weight: 800;
      letter-spacing: 1px;
      line-height: 90px; }
      header .landing_headerTextContent__1xCxk h2.landing_headerTextMobile__XHEri {
        display: none; }
      @media (max-width: 615px) {
        header .landing_headerTextContent__1xCxk h2.landing_headerText__DY75_ {
          display: none; }
        header .landing_headerTextContent__1xCxk h2.landing_headerTextMobile__XHEri {
          display: inline;
          display: initial;
          font-size: 3rem;
          line-height: 60px; } }
      @media (max-width: 500px) {
        header .landing_headerTextContent__1xCxk h2.landing_headerTextMobile__XHEri {
          font-size: 2.5rem; } }
  header .landing_headerSubText__2YSoD {
    color: white;
    margin: 30px 0px 5px;
    font-size: 1rem;
    line-height: 30px;
    font-family: "Open Sans", sans-serif; }
    @media (max-width: 500px) {
      header .landing_headerSubText__2YSoD {
        font-size: 0.9rem; } }
  header button {
    background: #fb515e;
    background: -webkit-gradient(linear, left top, right top, from(#fb515e), color-stop(50%, #fb5c57), to(#fb694f));
    background: -webkit-linear-gradient(left, #fb515e 0%, #fb5c57 50%, #fb694f 100%);
    background: linear-gradient(90deg, #fb515e 0%, #fb5c57 50%, #fb694f 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    border: none;
    border-radius: 7px;
    font-size: 0.9rem;
    font-family: "Open Sans", sans-serif;
    font-weight: 800;
    color: white;
    cursor: pointer;
    width: 200px;
    margin-top: 30px; }
    header button img {
      width: 25px;
      margin-right: 15px; }
  header .landing_comingSoon__3WJ0i {
    margin: 80px 0 184px;
    display: flex;
    align-items: center; }
    @media (max-width: 450px) {
      header .landing_comingSoon__3WJ0i {
        margin: 110px 0 184px; } }
    header .landing_comingSoon__3WJ0i img {
      width: 35px;
      margin: 0 5px; }
      header .landing_comingSoon__3WJ0i img:first-child {
        margin-left: 0; }
      header .landing_comingSoon__3WJ0i img:nth-child(2) {
        border-radius: 5px; }
    header .landing_comingSoon__3WJ0i p {
      margin-left: 10px;
      color: white;
      font-family: "Roboto", sans-serif; }

section {
  padding: 50px 50px 0 50px;
  overflow: hidden; }

.landing_featureSection__3vJQP {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: flex-end;
  margin: 30px auto 200px;
  width: 80%; }
  @media (max-width: 1075px) {
    .landing_featureSection__3vJQP {
      margin: 0 auto 200px; } }
  @media (max-width: 730px) {
    .landing_featureSection__3vJQP {
      margin: 0 auto 122px; } }
  @media (max-width: 1100px) {
    .landing_featureSection__3vJQP {
      width: 90%; } }
  .landing_featureSection__3vJQP .landing_feature__1LROK {
    min-width: 250px;
    max-height: 215px;
    font-family: "Roboto", sans-serif;
    line-height: 20px;
    width: 25%;
    margin: 90px 10px 0 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between; }
    .landing_featureSection__3vJQP .landing_feature__1LROK:first-of-type {
      margin-top: 0; }
    @media (max-width: 916px) {
      .landing_featureSection__3vJQP .landing_feature__1LROK {
        align-items: center;
        min-width: 300px; } }
    @media (max-width: 730px) {
      .landing_featureSection__3vJQP .landing_feature__1LROK {
        min-width: 250px;
        width: 90%; } }
    .landing_featureSection__3vJQP .landing_feature__1LROK .landing_featureIcon__3gH08 {
      width: 75px; }
    .landing_featureSection__3vJQP .landing_feature__1LROK .landing_featureMainText__5rWWI {
      font-weight: bold;
      margin: 15px 0px 5px 0px; }
    .landing_featureSection__3vJQP .landing_feature__1LROK .landing_featureSubText__3P_nq {
      margin: 15px 0px 5px 0px;
      font-size: 0.9rem;
      color: grey; }
      @media (max-width: 916px) {
        .landing_featureSection__3vJQP .landing_feature__1LROK .landing_featureSubText__3P_nq {
          text-align: center; } }

.landing_popularSection__35BXF {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .landing_popularSection__35BXF .landing_selectedCategory__2Sqfy {
    width: 100%;
    padding: 0 50px;
    background-color: #fff8f5; }
    @media (max-width: 825px) {
      .landing_popularSection__35BXF .landing_selectedCategory__2Sqfy {
        padding: 0 10px; } }
    .landing_popularSection__35BXF .landing_selectedCategory__2Sqfy button {
      background-color: white;
      color: red;
      cursor: pointer;
      font-family: "Roboto", sans-serif;
      font-weight: 800;
      font-size: 0.9rem;
      width: 150px;
      height: 40px;
      border: none;
      -webkit-transition: 0.5s;
      transition: 0.5s;
      box-shadow: 0px 0px 40px rgba(230, 50, 0, 0.05); }
      .landing_popularSection__35BXF .landing_selectedCategory__2Sqfy button:hover {
        box-shadow: 0px 0px 40px rgba(230, 50, 0, 0.15); }
    .landing_popularSection__35BXF .landing_selectedCategory__2Sqfy .landing_topRow__2lH_B {
      width: 100%;
      max-width: 1121px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto; }
      @media (max-width: 750px) {
        .landing_popularSection__35BXF .landing_selectedCategory__2Sqfy .landing_topRow__2lH_B {
          justify-content: center; } }
      @media (max-width: 750px) {
        .landing_popularSection__35BXF .landing_selectedCategory__2Sqfy .landing_topRow__2lH_B .landing_viewAll__1qVbf {
          display: none; } }
    .landing_popularSection__35BXF .landing_selectedCategory__2Sqfy .landing_bookCard__1uUEU {
      width: 500px;
      height: 280px;
      padding: 10px 0 10px 0;
      min-width: 450px;
      display: flex;
      align-items: center;
      position: relative; }
      @media (max-width: 1166px) {
        .landing_popularSection__35BXF .landing_selectedCategory__2Sqfy .landing_bookCard__1uUEU {
          left: 55px;
          height: 300px; } }
      @media (max-width: 700px) {
        .landing_popularSection__35BXF .landing_selectedCategory__2Sqfy .landing_bookCard__1uUEU {
          left: 21px; } }
      @media (max-width: 500px) {
        .landing_popularSection__35BXF .landing_selectedCategory__2Sqfy .landing_bookCard__1uUEU {
          min-width: 343px; } }
      .landing_popularSection__35BXF .landing_selectedCategory__2Sqfy .landing_bookCard__1uUEU:first-child {
        margin-right: 50px; }
      @media (max-width: 1166px) {
        .landing_popularSection__35BXF .landing_selectedCategory__2Sqfy .landing_bookCard__1uUEU:first-child {
          margin-right: 0; } }
      .landing_popularSection__35BXF .landing_selectedCategory__2Sqfy .landing_bookCard__1uUEU .landing_cardMainText__2qlHB {
        font-weight: 800;
        margin: 0px 0px 5px 0px; }
      .landing_popularSection__35BXF .landing_selectedCategory__2Sqfy .landing_bookCard__1uUEU .landing_cardSubText__1apHL {
        color: grey;
        line-height: 20px; }
      .landing_popularSection__35BXF .landing_selectedCategory__2Sqfy .landing_bookCard__1uUEU h2.landing_cardSubText__1apHL {
        margin: 0px 0px 10px;
        font-size: 0.9rem; }
      .landing_popularSection__35BXF .landing_selectedCategory__2Sqfy .landing_bookCard__1uUEU p.landing_cardSubText__1apHL {
        margin: 5px 0px 10px 0px;
        font-size: 0.8rem; }
      .landing_popularSection__35BXF .landing_selectedCategory__2Sqfy .landing_bookCard__1uUEU .landing_imageContainer__3dKwR {
        height: 350px;
        width: 200px;
        position: absolute;
        top: -35px;
        left: -35px;
        z-index: 100;
        overflow: hidden;
        border: 8px solid #28beb2;
        box-shadow: 0 2px 20px lightgrey; }
        @media (max-width: 1166px) {
          .landing_popularSection__35BXF .landing_selectedCategory__2Sqfy .landing_bookCard__1uUEU .landing_imageContainer__3dKwR {
            height: 295px;
            top: 5px;
            left: -40px; } }
        @media (max-width: 700px) {
          .landing_popularSection__35BXF .landing_selectedCategory__2Sqfy .landing_bookCard__1uUEU .landing_imageContainer__3dKwR {
            display: none; } }
        .landing_popularSection__35BXF .landing_selectedCategory__2Sqfy .landing_bookCard__1uUEU .landing_imageContainer__3dKwR img {
          position: relative;
          right: 15px;
          height: 100%;
          width: auto; }
      .landing_popularSection__35BXF .landing_selectedCategory__2Sqfy .landing_bookCard__1uUEU .landing_cardBase__2nQn8 {
        width: 100%;
        min-height: 290px;
        display: flex;
        justify-content: flex-end;
        background-color: #ffffff;
        border-radius: 15px;
        box-shadow: 2px 2px 20px lightgrey;
        padding-right: 10px; }
        @media (max-width: 1166px) {
          .landing_popularSection__35BXF .landing_selectedCategory__2Sqfy .landing_bookCard__1uUEU .landing_cardBase__2nQn8 {
            width: 90%;
            min-height: 230px; } }
        @media (max-width: 500px) {
          .landing_popularSection__35BXF .landing_selectedCategory__2Sqfy .landing_bookCard__1uUEU .landing_cardBase__2nQn8 {
            min-height: 280px; } }
        .landing_popularSection__35BXF .landing_selectedCategory__2Sqfy .landing_bookCard__1uUEU .landing_cardBase__2nQn8 .landing_cardContent__2EQt7 {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 62%;
          font-family: "Roboto", sans-serif; }
          @media (max-width: 1166px) {
            .landing_popularSection__35BXF .landing_selectedCategory__2Sqfy .landing_bookCard__1uUEU .landing_cardBase__2nQn8 .landing_cardContent__2EQt7 {
              width: 72%; } }
          @media (max-width: 750px) {
            .landing_popularSection__35BXF .landing_selectedCategory__2Sqfy .landing_bookCard__1uUEU .landing_cardBase__2nQn8 .landing_cardContent__2EQt7 {
              text-align: left; } }
          @media (max-width: 700px) {
            .landing_popularSection__35BXF .landing_selectedCategory__2Sqfy .landing_bookCard__1uUEU .landing_cardBase__2nQn8 .landing_cardContent__2EQt7 {
              padding-left: 15px;
              align-items: center;
              width: 100%; } }
          .landing_popularSection__35BXF .landing_selectedCategory__2Sqfy .landing_bookCard__1uUEU .landing_cardBase__2nQn8 .landing_cardContent__2EQt7 button {
            background: #fb515e;
            background: -webkit-gradient(linear, left top, right top, from(#fb515e), color-stop(50%, #fb5c57), to(#fb694f));
            background: -webkit-linear-gradient(left, #fb515e 0%, #fb5c57 50%, #fb694f 100%);
            background: linear-gradient(90deg, #fb515e 0%, #fb5c57 50%, #fb694f 100%);
            display: flex;
            align-items: center;
            justify-content: center;
            height: 50px;
            border: none;
            border-radius: 7px;
            font-size: 0.9rem;
            font-family: "Open Sans", sans-serif;
            font-weight: 800;
            color: white;
            cursor: pointer;
            width: 150px;
            height: 40px; }
    .landing_popularSection__35BXF .landing_selectedCategory__2Sqfy .landing_bottomRow__2O6Z1 {
      width: 100%;
      padding: 20px 0 20px 0;
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      max-width: 1085px;
      margin: 0 auto 50px;
      position: relative;
      left: 18px; }
      @media (max-width: 1166px) {
        .landing_popularSection__35BXF .landing_selectedCategory__2Sqfy .landing_bottomRow__2O6Z1 {
          display: none; } }
      @media (max-width: 750px) {
        .landing_popularSection__35BXF .landing_selectedCategory__2Sqfy .landing_bottomRow__2O6Z1 {
          justify-content: space-between; } }
      @media (max-width: 675px) {
        .landing_popularSection__35BXF .landing_selectedCategory__2Sqfy .landing_bottomRow__2O6Z1 {
          justify-content: space-evenly;
          flex-wrap: wrap; }
          .landing_popularSection__35BXF .landing_selectedCategory__2Sqfy .landing_bottomRow__2O6Z1 svg {
            margin-bottom: 60px; } }
    .landing_popularSection__35BXF .landing_selectedCategory__2Sqfy .landing_bottomRowMobile__2OpRa {
      display: none; }
      @media (max-width: 1166px) {
        .landing_popularSection__35BXF .landing_selectedCategory__2Sqfy .landing_bottomRowMobile__2OpRa {
          display: inline;
          display: initial; } }
      .landing_popularSection__35BXF .landing_selectedCategory__2Sqfy .landing_bottomRowMobile__2OpRa .landing_slider__3rV4Z {
        display: flex; }
      .landing_popularSection__35BXF .landing_selectedCategory__2Sqfy .landing_bottomRowMobile__2OpRa button {
        margin: 0;
        min-height: 48px; }
      .landing_popularSection__35BXF .landing_selectedCategory__2Sqfy .landing_bottomRowMobile__2OpRa .landing_innerSlide__33n1n {
        display: flex;
        align-items: center; }
        @media (max-width: 500px) {
          .landing_popularSection__35BXF .landing_selectedCategory__2Sqfy .landing_bottomRowMobile__2OpRa .landing_innerSlide__33n1n {
            height: auto; } }
      .landing_popularSection__35BXF .landing_selectedCategory__2Sqfy .landing_bottomRowMobile__2OpRa .landing_buttonsContainer__2bkM- {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 50px 0;
        width: 90%;
        margin: 50px auto;
        min-width: 363px; }
        @media (max-width: 700px) {
          .landing_popularSection__35BXF .landing_selectedCategory__2Sqfy .landing_bottomRowMobile__2OpRa .landing_buttonsContainer__2bkM- {
            margin: 25px auto 50px; } }
        .landing_popularSection__35BXF .landing_selectedCategory__2Sqfy .landing_bottomRowMobile__2OpRa .landing_buttonsContainer__2bkM- .landing_controlButton__3B_IO {
          background-color: transparent;
          border: none;
          box-shadow: none;
          width: auto; }
          .landing_popularSection__35BXF .landing_selectedCategory__2Sqfy .landing_bottomRowMobile__2OpRa .landing_buttonsContainer__2bkM- .landing_controlButton__3B_IO svg {
            font-size: 3rem; }
    .landing_popularSection__35BXF .landing_selectedCategory__2Sqfy .landing_viewAllMobile__t7pAW {
      display: none; }
    @media (max-width: 750px) {
      .landing_popularSection__35BXF .landing_selectedCategory__2Sqfy {
        text-align: center; }
        .landing_popularSection__35BXF .landing_selectedCategory__2Sqfy .landing_viewAllMobile__t7pAW {
          display: inline;
          display: initial;
          margin: 0 0 40px; } }
  .landing_popularSection__35BXF .landing_categoryMenu__f75vB {
    font-family: "Roboto", sans-serif;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 60px 0;
    width: 90%; }
    @media (max-width: 860px) {
      .landing_popularSection__35BXF .landing_categoryMenu__f75vB {
        flex-direction: column; } }
    .landing_popularSection__35BXF .landing_categoryMenu__f75vB .landing_categoryMainText__3iew_ {
      font-weight: 800;
      font-size: 2.5rem;
      line-height: 45px; }
      .landing_popularSection__35BXF .landing_categoryMenu__f75vB .landing_categoryMainText__3iew_:nth-of-type(2) {
        color: #fb515e; }
      @media (max-width: 500px) {
        .landing_popularSection__35BXF .landing_categoryMenu__f75vB .landing_categoryMainText__3iew_ {
          font-size: 2rem; } }
    .landing_popularSection__35BXF .landing_categoryMenu__f75vB .landing_categorySubText__14EAb {
      margin: 10px 0 30px;
      font-family: "Roboto", sans-serif;
      font-size: 0.9rem;
      line-height: 25px; }
      @media (max-width: 560px) {
        .landing_popularSection__35BXF .landing_categoryMenu__f75vB .landing_categorySubText__14EAb {
          text-align: center; } }
    .landing_popularSection__35BXF .landing_categoryMenu__f75vB .landing_categoryDescription__3DCFl {
      width: 45%; }
      @media (max-width: 860px) {
        .landing_popularSection__35BXF .landing_categoryMenu__f75vB .landing_categoryDescription__3DCFl {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center; } }
    .landing_popularSection__35BXF .landing_categoryMenu__f75vB .landing_categoryOptions__2PG40 {
      width: 45%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly; }
      @media (max-width: 860px) {
        .landing_popularSection__35BXF .landing_categoryMenu__f75vB .landing_categoryOptions__2PG40 {
          width: 100%; } }
      .landing_popularSection__35BXF .landing_categoryMenu__f75vB .landing_categoryOptions__2PG40 button {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: 150px;
        height: 50px;
        margin: 10px;
        padding: 0;
        border: none;
        border-radius: 7px;
        background-color: white;
        box-shadow: 11px 10px 20px #ebebeb;
        color: black;
        font-family: "Roboto", sans-serif;
        font-size: 0.8rem;
        font-weight: 800;
        -webkit-transition: 0.5s;
        transition: 0.5s;
        cursor: pointer; }
        .landing_popularSection__35BXF .landing_categoryMenu__f75vB .landing_categoryOptions__2PG40 button.landing_activeButton__1WybX {
          background: #ff8e59;
          background: -webkit-gradient(linear, left top, right top, from(#ff8e59), to(#ffb153));
          background: -webkit-linear-gradient(left, #ff8e59 0%, #ffb153 100%);
          background: linear-gradient(90deg, #ff8e59 0%, #ffb153 100%); }

.landing_highlightSection__1Li-p {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; }
  .landing_highlightSection__1Li-p .landing_highlightRow__WOHH0 {
    width: 90%;
    margin: 0 auto 80px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media (max-width: 700px) {
      .landing_highlightSection__1Li-p .landing_highlightRow__WOHH0 {
        flex-direction: column;
        margin-bottom: 0px; }
        .landing_highlightSection__1Li-p .landing_highlightRow__WOHH0:nth-of-type(2) {
          flex-direction: column-reverse; } }
    .landing_highlightSection__1Li-p .landing_highlightRow__WOHH0 .landing_highlightImageContainer__3g5_h {
      width: 50%;
      border-radius: 5px;
      overflow: hidden; }
      @media (max-width: 700px) {
        .landing_highlightSection__1Li-p .landing_highlightRow__WOHH0 .landing_highlightImageContainer__3g5_h {
          width: 115%;
          height: 200px; } }
      .landing_highlightSection__1Li-p .landing_highlightRow__WOHH0 .landing_highlightImageContainer__3g5_h .landing_highlightImage__3oRF- {
        width: 100%;
        height: auto; }
        @media (max-width: 700px) {
          .landing_highlightSection__1Li-p .landing_highlightRow__WOHH0 .landing_highlightImageContainer__3g5_h .landing_highlightImage__3oRF- {
            position: relative;
            bottom: 50%; } }
    .landing_highlightSection__1Li-p .landing_highlightRow__WOHH0 .landing_highlightContent__2372y {
      width: 50%; }
      @media (max-width: 700px) {
        .landing_highlightSection__1Li-p .landing_highlightRow__WOHH0 .landing_highlightContent__2372y {
          margin: 30px;
          width: 90%; } }
    .landing_highlightSection__1Li-p .landing_highlightRow__WOHH0 .landing_highlightMainText__zUeuu {
      width: 80%;
      margin: 20px auto;
      font-size: 2rem;
      font-weight: bold; }
    .landing_highlightSection__1Li-p .landing_highlightRow__WOHH0 .landing_highlightSubText__27Aea {
      width: 80%;
      margin: 20px auto;
      line-height: 20px;
      color: grey; }
    .landing_highlightSection__1Li-p .landing_highlightRow__WOHH0 .landing_redBtn__1f_hi {
      width: 80%;
      margin: 20px auto; }
      .landing_highlightSection__1Li-p .landing_highlightRow__WOHH0 .landing_redBtn__1f_hi button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        border: none;
        border-radius: 7px;
        font-size: 0.9rem;
        font-family: "Open Sans", sans-serif;
        font-weight: 800;
        color: white;
        cursor: pointer;
        width: 150px;
        color: #fb5662;
        background-color: #fed0cb; }

.landing_ctaSection__134bc {
  display: flex;
  justify-content: space-evenly;
  margin-top: 100px;
  height: 350px;
  background-color: #fd9a5e;
  overflow: visible; }
  .landing_ctaSection__134bc .landing_ctaContent__pv7EB {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 350px; }
  .landing_ctaSection__134bc .landing_ctaMainText__3Uz0q {
    color: white;
    line-height: 40px;
    font-size: 2rem;
    font-family: "Roboto", sans-serif;
    font-weight: 800;
    letter-spacing: 1px; }
  .landing_ctaSection__134bc .landing_ctaSubText__27hMp {
    color: white;
    line-height: 25px;
    font-family: "Roboto", sans-serif;
    font-size: 0.9rem;
    margin: 30px 0; }
  .landing_ctaSection__134bc button {
    background: #fb515e;
    background: -webkit-gradient(linear, left top, right top, from(#fb515e), color-stop(50%, #fb5c57), to(#fb694f));
    background: -webkit-linear-gradient(left, #fb515e 0%, #fb5c57 50%, #fb694f 100%);
    background: linear-gradient(90deg, #fb515e 0%, #fb5c57 50%, #fb694f 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    border: none;
    border-radius: 7px;
    font-size: 0.9rem;
    font-family: "Open Sans", sans-serif;
    font-weight: 800;
    color: white;
    cursor: pointer;
    width: 175px;
    font-size: 0.7rem; }
  .landing_ctaSection__134bc .landing_ctaImageContainer__2nGr8 {
    position: relative;
    bottom: 84px; }
    @media (max-width: 700px) {
      .landing_ctaSection__134bc .landing_ctaImageContainer__2nGr8 {
        display: none; } }
    .landing_ctaSection__134bc .landing_ctaImageContainer__2nGr8 img {
      width: 300px; }

footer {
  display: flex;
  background-color: #161b52; }
  footer .landing_logo__1BYzm p {
    font-size: 1.5rem;
    font-family: "BenchNine", sans-serif;
    letter-spacing: 1px; }
  footer .landing_footerMainText__1pShz {
    color: white;
    font-weight: bold;
    margin: 0px 0px 5px;
    font-family: "Roboto", sans-serif;
    line-height: 25px;
    font-size: 1rem; }
  footer .landing_footerSubText__254pN {
    color: white;
    margin: 5px 0px;
    font-family: "Roboto", sans-serif; }
  footer .landing_topBar__1THQ3 {
    width: 100%;
    padding: 0 60px;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap; }
    footer .landing_topBar__1THQ3 .landing_column__40Up4 {
      max-width: 25%;
      min-width: 75px;
      margin: 60px 10px 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start; }
      footer .landing_topBar__1THQ3 .landing_column__40Up4:first-child, footer .landing_topBar__1THQ3 .landing_column__40Up4:last-child {
        min-width: 187px; }
      footer .landing_topBar__1THQ3 .landing_column__40Up4 button {
        background: #fb515e;
        background: -webkit-gradient(linear, left top, right top, from(#fb515e), color-stop(50%, #fb5c57), to(#fb694f));
        background: -webkit-linear-gradient(left, #fb515e 0%, #fb5c57 50%, #fb694f 100%);
        background: linear-gradient(90deg, #fb515e 0%, #fb5c57 50%, #fb694f 100%);
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        border: none;
        border-radius: 7px;
        font-size: 0.9rem;
        font-family: "Open Sans", sans-serif;
        font-weight: 800;
        color: white;
        cursor: pointer;
        width: 150px; }
    footer .landing_topBar__1THQ3 input {
      width: 100%;
      height: 40px;
      font-size: 16px;
      color: white;
      border: 1px solid #a5a3bb;
      background-color: #45558a;
      margin: 20px 0; }
  footer .landing_bottomBar__1PZz1 {
    width: 100%;
    padding: 20px;
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #2e3164; }
    footer .landing_bottomBar__1PZz1 .landing_shareButtons__1JUFj {
      display: flex;
      justify-content: space-between;
      width: 25%; }

.App_App__15LM- {
  margin: 0 auto;
  display: unset;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%; }

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0 auto;
  width: 100%;
  max-width: 1800px; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  font-size: 62.5%;
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
  line-height: 30px; }

