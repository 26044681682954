@import 'SCSS/variables.scss';
@import 'SCSS/mixins.scss';

header,
section,
footer {
  // Minimum width to prevent a scroll
  // bar at 400px screen
  min-width: 383px;
}

.LandingPage {
  display: flex;
  flex-direction: column;
  max-width: 1500px;
  width: 100%;
  margin: 0 auto;
}

.mainText {
  font-family: $font;
  font-size: 2rem;
  font-weight: 800;
  line-height: 60px;
  margin: 30px 0;
  min-width: 400px;

  @media (max-width: 500px) {
    font-size: 1.5rem;
    margin: 15px 0;
  }
}

.logo {
  font-family: $logoFont;
  font-size: 1.7rem;
  margin-bottom: 10px;

  a {
    display: flex;
    color: $white;
    text-decoration: none;

    p:first-child {
      font-weight: 800;
    }
  }
}

// *** Header
$headerTablet: '(max-width: 1075px)';
$headerMobile: '(max-width: 615px)';
header {
  background-image: url('../../assets/LandingPage/Header/background.png');
  background-repeat: no-repeat;
  background-size: 1500px 900px;
  min-height: 900px;
  position: relative;
  margin-bottom: 60px;

  @media #{$headerTablet} {
    min-height: 820px;
  }

  nav {
    display: flex;
    justify-content: space-between;

    width: 80%;
    margin: 30px auto 0;

    @media #{$headerMobile} {
      flex-direction: column;
      align-items: center;

      div {
        line-height: 60px;
      }
    }

    div {
      font-family: $font;
      font-size: 1rem;
      display: flex;
      justify-content: space-between;

      &.account {
        width: 20%;

        @media #{$headerTablet} {
          width: 35%;
        }

        @media #{$headerMobile} {
          display: none;
        }
      }

      &.mobileMenu {
        display: none;

        @media #{$headerMobile} {
          display: initial;
          position: absolute;
          right: 35px;
          top: 15px;
        }
      }

      svg {
        color: $white;
      }
    }

    a {
      color: $white;
      display: flex;
      letter-spacing: 1px;

      text-decoration: none;
    }

    p:first-child {
      font-weight: 800;
    }
  } // nav

  .headerBackground {
    width: 100%;
    height: auto;
  }

  .headerContent {
    margin: 60px auto 0;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    width: 90%;

    @media (max-width: 450px) {
      margin: 0 auto;
    }
  }

  img.phone {
    // Phone image in header
    // margin-top: 94px;
    width: 375px;

    @media #{$headerTablet} {
      display: none;
    }
  }

  .headerTextContent {
    max-width: 555px;
    // margin-bottom: 300px;

    @media #{$headerTablet} {
      margin-top: 65px;
      display: flex;
      flex-direction: column;
      align-items: center;

      text-align: center;
    }

    h2 {
      color: $white;
      margin: 0;
      font-family: $font;
      font-size: 5rem;
      font-weight: 800;
      letter-spacing: 1px;
      line-height: 90px;

      &.headerTextMobile {
        display: none;
      }

      @media #{$headerMobile} {
        &.headerText {
          display: none;
        }

        &.headerTextMobile {
          display: initial;

          font-size: 3rem;
          line-height: 60px;
        }
      }

      @media #{$mobile} {
        &.headerTextMobile {
          font-size: 2.5rem;
        }
      }
    }
  }

  .headerSubText {
    color: $white;
    margin: 30px 0px 5px;
    font-size: 1rem;
    line-height: 30px;
    font-family: $smFont;

    @media #{$mobile} {
      font-size: 0.9rem;
    }
  }

  button {
    @include redBtn;
    @include genericBtn;

    width: 200px;
    margin-top: 30px;

    img {
      width: 25px;
      margin-right: 15px;
    }
  }
  .comingSoon {
    margin: 80px 0 184px;
    display: flex;
    align-items: center;

    @media (max-width: 450px) {
      margin: 110px 0 184px;
    }

    img {
      width: 35px;
      margin: 0 5px;

      &:first-child {
        // No left margin on iMessage logo
        margin-left: 0;
      }
      &:nth-child(2) {
        // curve edges of Slack logo
        border-radius: 5px;
      }
    }

    p {
      margin-left: 10px;
      color: $white;
      font-family: $font;
    }
  }
}
// *** End Header

section {
  // background-color: lightgrey;
  // width: 100%;
  padding: 50px 50px 0 50px;
  // min-width: 468px;
  overflow: hidden;
}

// *** Features Section
.featureSection {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: flex-end;

  margin: 30px auto 200px;
  width: 80%;

  @media #{$headerTablet} {
    margin: 0 auto 200px;
  }

  @media (max-width: 730px) {
    margin: 0 auto 122px;
  }

  @media (max-width: 1100px) {
    width: 90%;
  }

  .feature {
    min-width: 250px;
    max-height: 215px;
    font-family: $font;
    line-height: 20px;
    width: 25%;
    margin: 90px 10px 0 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    &:first-of-type {
      margin-top: 0;
    }

    @media (max-width: 916px) {
      align-items: center;
      min-width: 300px;
    }

    @media (max-width: 730px) {
      min-width: 250px;
      width: 90%;
    }

    .featureIcon {
      width: 75px;
    }

    .featureMainText {
      font-weight: bold;
      margin: 15px 0px 5px 0px;
    }

    .featureSubText {
      margin: 15px 0px 5px 0px;
      font-size: 0.9rem;
      color: grey;

      @media (max-width: 916px) {
        text-align: center;
      }
    }
  }
}
// *** End Features Section

// *** Popular Books Section
$popularTablet: '(max-width: 1166px)';
$popularMobile: '(max-width: 700px)';
.popularSection {
  display: flex;
  flex-direction: column;
  align-items: center;

  .selectedCategory {
    width: 100%;
    padding: 0 50px;
    background-color: #fff8f5;

    @media (max-width: 825px) {
      padding: 0 10px;
    }

    button {
      background-color: $white;
      color: red;
      cursor: pointer;

      font-family: $font;
      font-weight: 800;
      font-size: 0.9rem;

      width: 150px;
      height: 40px;

      border: none;
      transition: 0.5s;

      box-shadow: 0px 0px 40px rgba(230, 50, 0, 0.05);

      &:hover {
        box-shadow: 0px 0px 40px rgba(230, 50, 0, 0.15);
      }
    }

    .topRow {
      width: 100%;
      max-width: 1121px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto;

      @media (max-width: 750px) {
        justify-content: center;
      }

      .viewAll {
        @media (max-width: 750px) {
          display: none;
        }
      }
    }

    .bookCard {
      width: 500px;
      height: 280px;
      padding: 10px 0 10px 0;
      min-width: 450px;
      display: flex;
      align-items: center;
      position: relative;

      @media #{$popularTablet} {
        left: 55px;
        height: 300px;
      }

      @media #{$popularMobile} {
        left: 21px;
      }

      @media #{$mobile} {
        min-width: 343px;
      }

      &:first-child {
        margin-right: 50px;
      }

      @media (max-width: 1166px) {
        &:first-child {
          margin-right: 0;
        }
      }

      .cardMainText {
        font-weight: 800;
        margin: 0px 0px 5px 0px;
      }

      .cardSubText {
        color: grey;
        line-height: 20px;
      }

      h2.cardSubText {
        margin: 0px 0px 10px;
        font-size: 0.9rem;
      }

      p.cardSubText {
        margin: 5px 0px 10px 0px;
        font-size: 0.8rem;
      }
      .imageContainer {
        height: 350px;
        width: 200px;
        position: absolute;
        top: -35px;
        left: -35px;
        z-index: 100;
        overflow: hidden;
        border: 8px solid #28beb2;
        box-shadow: 0 2px 20px lightgrey;

        @media #{$popularTablet} {
          height: 295px;
          top: 5px;
          left: -40px;
        }

        @media #{$popularMobile} {
          display: none;
        }

        img {
          position: relative;
          right: 15px;

          height: 100%;
          width: auto;
        }
      }

      .cardBase {
        width: 100%;
        min-height: 290px;
        display: flex;
        justify-content: flex-end;
        background-color: #ffffff;
        border-radius: 15px;
        box-shadow: 2px 2px 20px lightgrey;
        padding-right: 10px;

        @media #{$popularTablet} {
          width: 90%;
          min-height: 230px;
        }

        @media #{$mobile} {
          min-height: 280px;
        }

        .cardContent {
          display: flex;
          flex-direction: column;
          justify-content: center;

          width: 62%;
          font-family: $font;

          @media #{$popularTablet} {
            width: 72%;
          }

          @media (max-width: 750px) {
            text-align: left;
          }

          @media #{$popularMobile} {
            padding-left: 15px;
            align-items: center;
            width: 100%;
          }

          button {
            @include redBtn;
            @include genericBtn;
            width: 150px;
            height: 40px;

            @media #{$popularTablet} {
              //   position: relative;
              //   bottom: 100px;
              //   left: 100px;
            }
          }
        }
      }
    }

    .bottomRow {
      width: 100%;
      padding: 20px 0 20px 0;
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      max-width: 1085px;
      margin: 0 auto 50px;
      position: relative;
      left: 18px;

      @media #{$popularTablet} {
        display: none;
      }

      @media (max-width: 750px) {
        justify-content: space-between;
      }

      @media (max-width: 675px) {
        justify-content: space-evenly;
        flex-wrap: wrap;

        svg {
          margin-bottom: 60px;
        }
      }
    }

    .bottomRowMobile {
      display: none;

      @media #{$popularTablet} {
        display: initial;
      }

      .slider {
        display: flex;
      }

      button {
        margin: 0;
        min-height: 48px;
      }

      .innerSlide {
        display: flex;
        align-items: center;

        @media (max-width: 500px) {
          height: auto;
        }
      }

      .buttonsContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 50px 0;
        width: 90%;
        margin: 50px auto;
        min-width: 363px;

        @media #{$popularMobile} {
          margin: 25px auto 50px;
        }

        .controlButton {
          background-color: transparent;
          border: none;
          box-shadow: none;
          width: auto;

          svg {
            font-size: 3rem;
          }
        }
      }
    }

    .viewAllMobile {
      display: none;
    }

    @media (max-width: 750px) {
      text-align: center;
      .viewAllMobile {
        display: initial;
        margin: 0 0 40px;
      }
    }
  }

  $categoryTablet: '(max-width: 860px)';
  $categoryMobile: '(max-width: 500px)';
  .categoryMenu {
    font-family: $font;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 60px 0;
    width: 90%;

    @media #{$categoryTablet} {
      flex-direction: column;
    }

    .categoryMainText {
      font-weight: 800;
      font-size: 2.5rem;
      line-height: 45px;

      &:nth-of-type(2) {
        color: $red;
      }

      @media #{$categoryMobile} {
        font-size: 2rem;
      }
    }

    .categorySubText {
      margin: 10px 0 30px;

      font-family: $font;
      font-size: 0.9rem;
      line-height: 25px;

      @media (max-width: 560px) {
        text-align: center;
      }
    }

    .categoryDescription {
      width: 45%;

      @media #{$categoryTablet} {
        width: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    .categoryOptions {
      width: 45%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;

      @media #{$categoryTablet} {
        width: 100%;
      }

      button {
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        width: 150px;
        height: 50px;
        margin: 10px;
        padding: 0;
        border: none;
        border-radius: 7px;
        background-color: $white;
        box-shadow: 11px 10px 20px rgba(235, 235, 235, 1);

        color: black;
        font-family: $font;
        font-size: 0.8rem;
        font-weight: 800;

        transition: 0.5s;

        cursor: pointer;

        &.activeButton {
          background: rgb(255, 142, 89);
          background: linear-gradient(
            90deg,
            rgba(255, 142, 89, 1) 0%,
            rgba(255, 177, 83, 1) 100%
          );
        }
      }
    }
  }
}
// *** End Popular Books Section

// **** Highlights Section
$highlightTablet: '(max-width: 700px)';
.highlightSection {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .highlightRow {
    width: 90%;
    margin: 0 auto 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media #{$highlightTablet} {
      flex-direction: column;
      margin-bottom: 0px;

      &:nth-of-type(2) {
        flex-direction: column-reverse;
      }
    }

    .highlightImageContainer {
      width: 50%;
      border-radius: 5px;
      overflow: hidden;
      @media #{$highlightTablet} {
        width: 115%;
        height: 200px;
      }

      .highlightImage {
        width: 100%;
        height: auto;

        @media #{$highlightTablet} {
          position: relative;
          bottom: 50%;
        }
      }
    }

    .highlightContent {
      width: 50%;

      @media #{$highlightTablet} {
        margin: 30px;
        width: 90%;
      }
    }

    .highlightMainText {
      width: 80%;
      margin: 20px auto;

      font-size: 2rem;
      font-weight: bold;
    }

    .highlightSubText {
      width: 80%;
      margin: 20px auto;
      line-height: 20px;
      color: grey;
    }

    .redBtn {
      width: 80%;
      margin: 20px auto;

      button {
        @include genericBtn;
        width: 150px;
        // height: 40px;

        color: #fb5662;
        background-color: #fed0cb;
      }
    }
  }
}
// **** End Highlights Section

// *** CTA Section
.ctaSection {
  display: flex;
  justify-content: space-evenly;

  margin-top: 100px;
  height: 350px;
  background-color: #fd9a5e;

  overflow: visible;

  .ctaContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 350px;
  }

  .ctaMainText {
    color: $white;
    line-height: 40px;
    font-size: 2rem;
    font-family: $font;
    font-weight: 800;
    letter-spacing: 1px;
  }

  .ctaSubText {
    color: $white;
    line-height: 25px;
    font-family: $font;
    font-size: 0.9rem;
    margin: 30px 0;
  }

  button {
    @include redBtn;
    @include genericBtn;

    width: 175px;
    font-size: 0.7rem;
  }

  .ctaImageContainer {
    position: relative;
    bottom: 84px;

    @media (max-width: 700px) {
      display: none;
    }

    img {
      width: 300px;
    }
  }
}
// *** End CTA Section

// *** Footer
footer {
  display: flex;
  background-color: #161b52;
  // min-width: 468px;

  .logo {
    p {
      font-size: 1.5rem;
      font-family: $logoFont;
      letter-spacing: 1px;
    }
  }

  .footerMainText {
    color: $white;
    font-weight: bold;
    margin: 0px 0px 5px;

    font-family: $font;
    line-height: 25px;
    font-size: 1rem;
  }

  .footerSubText {
    color: $white;
    margin: 5px 0px;
    font-family: $font;
  }

  .topBar {
    width: 100%;
    padding: 0 60px;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;

    .column {
      max-width: 25%;
      min-width: 75px;
      margin: 60px 10px 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      &:first-child,
      &:last-child {
        min-width: 187px;
      }

      button {
        @include redBtn;
        @include genericBtn;

        width: 150px;
      }
    }

    input {
      width: 100%;
      height: 40px;
      font-size: 16px;
      color: $white;
      border: 1px solid #a5a3bb;
      background-color: #45558a;
      margin: 20px 0;
    }
  }

  .bottomBar {
    width: 100%;
    padding: 20px;
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #2e3164;

    .shareButtons {
      display: flex;
      justify-content: space-between;
      width: 25%;
    }
  }
}
// *** End Footer
