@import url('https://fonts.googleapis.com/css?family=BenchNine:300|Roboto|Open+Sans&display=swap');

// Fonts
$logoFont: 'BenchNine', sans-serif;
$font: 'Roboto', sans-serif;
$smFont: 'Open Sans', sans-serif;

// Colors
$white: white;
$red: rgba(251, 81, 94, 1);

// Widths
$mobile: '(max-width: 500px)';
