@import 'SCSS/variables.scss';

@mixin redBtn {
  background: rgb(251, 81, 94);
  background: linear-gradient(
    90deg,
    rgba(251, 81, 94, 1) 0%,
    rgba(251, 92, 87, 1) 50%,
    rgba(251, 105, 79, 1) 100%
  );
}

@mixin genericBtn {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 50px;

  border: none;
  border-radius: 7px;

  font-size: 0.9rem;
  font-family: $smFont;
  font-weight: 800;
  color: $white;

  cursor: pointer;
}
